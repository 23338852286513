<template>
  <router-header :routerHeaderInfo="routerHeaderInfo"></router-header>

  <div class="customer-detail">
    <span class="text-16">所属员工：</span>
    <a-radio-group v-model:value="relationId"
                   @change="changeStaff($event)">
      <a-radio-button v-for="staff in staffNames"
                      :key="staff.id"
                      :value="staff.id">
        <div v-is="'ww-open-data'"
             class="ww-open-data"
             type="userName"
             :openid="staff.staffName"></div>
      </a-radio-button>
    </a-radio-group>

    <div class="flex justify-between items-center p-24 mt-24 bg-color-f5">
      <avatar-column class="flex-shrink-0"
                     width="unset"
                     corpWidth="unset"
                     :avatar="{avatar: customer.avatar, name: customer.realName || customer.wechatName, nickname: customer.wechatName, corpName: customer.wechatCorpName}"
                     :userType="customer.userType" />
      <div class="flex flex-wrap items-start ml-16">
        <p class="flex-shrink-0">标签：</p>
        <div class="flex-1">
          <a-tag v-for="tags in customer.tags"
                 :key="tags.id"
                 class="border-none">{{tags.name}}</a-tag>
        </div>

        <svg-icon class="mt-4 text-color-999 hover:text-admin-primary"
                  type="iconbianji"
                  @click="tagVisible = true" />

        <customer-tag-modal v-model:visible="tagVisible"
                            :tags="customer.tags"
                            :id="relationId"
                            @created="updateTag">
        </customer-tag-modal>
      </div>
    </div>

    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="customer"
                  tab="客户概况">

        <div class="title-mark mt-8">客服信息</div>
        <a-descriptions layout="vertical"
                        bordered>
          <a-descriptions-item label="所属成员">
            <avatar-column :avatar="{avatar: customer.staffAvatar, userId: customer.staffName}"
                           round
                           is-user-id />
          </a-descriptions-item>
          <a-descriptions-item label="客户来源">{{ customer.sourceContent }}</a-descriptions-item>
          <a-descriptions-item label="添加时间">{{ customer.createdTime }}</a-descriptions-item>
        </a-descriptions>

        <p class="title-mark">基本信息</p>
        <div class="basic-info">
          <a-descriptions :column="2"
                          bordered
                          style="word-break: break-word;">
            <a-descriptions-item label="来源">{{ customer.sourceContent || ' - ' }}</a-descriptions-item>
            <a-descriptions-item label="电话">{{ customer.phone }}</a-descriptions-item>
            <a-descriptions-item label="性别">{{ customer.sex === sexEnum.MALE ? "男" : "女" }}</a-descriptions-item>
            <a-descriptions-item label="年龄">{{ customer.age }}</a-descriptions-item>
            <a-descriptions-item label="描述">{{ customer.about }}</a-descriptions-item>
            <a-descriptions-item label="邮箱">{{ customer.email }}</a-descriptions-item>
            <a-descriptions-item label="QQ">{{ customer.qq }}</a-descriptions-item>
            <a-descriptions-item label="微博">{{ customer.weibo }}</a-descriptions-item>
          </a-descriptions>
        </div>

        <p class="title-mark">客户阶段</p>
        <a-steps :current="currentStep"
                 labelPlacement="vertical">
          <a-step v-for="stage in customerStages"
                  :key="stage.code"
                  :title="stage.name" />
        </a-steps>

        <p class="title-mark">客户动态</p>
        <div class="ml-28 overflow-y-auto"
             style="max-height: 400px;">
          <a-timeline v-for="timeLine in customer.records"
                      :key="timeLine.createdDate">
            <span class="inline-block h-28 py-4 px-12 mb-24 mr-24 bg-color-f5">{{ timeLine.createdDate }}</span>
            <a-timeline-item v-for="(item, index) in timeLine.customerFollowUpRecords"
                             :key="item.createdTime"
                             style="marginLeft: 52px;">
              <p v-if="index === 0 || !isTimeSame(timeLine.customerFollowUpRecords, index)"
                 class="mb-8">
                {{ $f.datetime(item.createdTime, 'YYYY-MM-DD HH:mm') }}
                <span class="font-medium">「{{ item.title }}」</span>
              </p>
              <p class=" text-color-666">{{ item.content }} </p>
            </a-timeline-item>
          </a-timeline>
        </div>

      </a-tab-pane>
      <a-tab-pane key="groupChat"
                  tab="所在群聊">
        <a-table class="main-table"
                 :columns="columns"
                 :data-source="group"
                 :pagination="false"
                 row-key="workWechatChatId">
          <template #ownerName="{text}">
            <div v-is="'ww-open-data'"
                 v-if="text"
                 class="ww-open-data"
                 type="userName"
                 :openid="text"></div>
          </template>

          <template #joinTime="{ text }">
            {{ $f.datetime(text, 'YYYY-MM-DD HH:mm') }}
          </template>
          <template #isJoin="{ text }">
            <a-tag class="bg-admin-primary bg-opacity-10 text-admin-primary border-none"
                   :class="{'text-color-999': !text}">{{ text ? '我在群聊中' : '我不在群聊中' }}</a-tag>
          </template>
          <template #createdTime="{ text }">
            {{ $f.datetime(text, 'YYYY-MM-DD HH:mm') }}
          </template>
        </a-table>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { Descriptions, Steps, Timeline, Radio, Tabs } from "ant-design-vue";
import _ from "lodash";
import { computed } from "vue";
import RouterHeader from "@/components/RouterHeader.vue";
import CustomerTagModal from "./CustomerTagModal.vue";
import AvatarColumn from "@/components/AvatarColumn";
import SvgIcon from "@/components/SvgIcon";

import customerApi from "@/service/api/customer";
import customerStateApi from "@/service/api/CustomerStateApi";
import filters from "@/shared/service/filters";
import { reactive, ref } from "@vue/reactivity";
import { useRoute } from "vue-router";
import { getWxAgentConfig } from "@/global";

export default {
  name: "CustomerDetail",
  components: {
    ASteps: Steps,
    AStep: Steps.Step,
    ADescriptions: Descriptions,
    ADescriptionsItem: Descriptions.Item,
    ATimeline: Timeline,
    ATimelineItem: Timeline.Item,
    ARadioGroup: Radio.Group,
    ARadioButton: Radio.Button,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,

    RouterHeader,
    SvgIcon,
    CustomerTagModal,
    AvatarColumn,
  },

  setup() {
    const routerHeaderInfo = ref([
      {
        path: "/customer/customer",
        name: "客户管理",
      },
      { name: "客户详情" },
    ]);

    const sexEnum = {
      MALE: 1,
      FEMALE: 2,
    };

    const route = new useRoute();

    const { id, customerId } = route.query;

    const relationId = ref(Number(id));

    // 员工列表
    const staffNames = ref([]);
    const getStaffList = async () => {
      staffNames.value = await customerApi.getRelationList(customerId);
    };
    const changeStaff = async (event) => {
      relationId.value = event.target.value;
      getCustomer();
      getGroupChatlList();
    };

    const activeKey = ref("customer");

    // 客户详情
    const customer = reactive({});
    const getCustomer = async () => {
      await customerApi.getRelation(relationId.value).then((res) => {
        _.assign(customer, res);
      });
    };

    // 标签选择
    const tagVisible = ref(false);
    const updateTag = async () => {
      await customerApi.getRelation(relationId.value).then((res) => {
        _.assign(customer, res);
      });
    };

    // 客户状态
    const currentStep = computed(() => {
      const index = _.findIndex(customerStages.value, (item) => {
        return item.code === customer.stage;
      });

      return index || 0;
    });

    const customerStages = ref([]);
    const getCustomerStages = async () => {
      customerStages.value = await customerStateApi.findAllStages({
        withNoneStage: true,
      });
    };

    // 时间轴
    const isTimeSame = (record, index) => {
      const previousTime = filters.datetime(
        record[index - 1].createdTime,
        "YYYY-MM-DD HH:mm"
      );
      const lastTime = filters.datetime(
        record[index].createdTime,
        "YYYY-MM-DD HH:mm"
      );

      return previousTime === lastTime;
    };

    // 客户所在群聊
    const columns = [
      {
        title: "群名称",
        dataIndex: "name",
        width: "20.5%",
      },
      {
        title: "群主",
        dataIndex: "ownerName",
        slots: { customRender: "ownerName" },
      },
      {
        title: "人数",
        dataIndex: "totalAddCnt",
      },
      {
        title: "客户入群时间",
        dataIndex: "joinTime",
        slots: { customRender: "joinTime" },
      },
      {
        title: "状态",
        dataIndex: "isJoin",
        slots: { customRender: "isJoin" },
      },
      {
        title: "添加时间",
        dataIndex: "createdTime",
        slots: { customRender: "createdTime" },
      },
    ];

    const group = ref([]);
    const getGroupChatlList = async () => {
      group.value = await customerApi.getGroupChatlList({
        id: relationId.value,
      });
    };

    getWxAgentConfig();
    getStaffList();
    getGroupChatlList();
    getCustomerStages();
    getCustomer();

    return {
      routerHeaderInfo,
      sexEnum,
      relationId,

      activeKey,
      staffNames,
      changeStaff,

      customer,

      tagVisible,
      updateTag,

      currentStep,
      customerStages,

      isTimeSame,

      columns,
      group,
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const length = vm.routerHeaderInfo.length;
      const path = vm.routerHeaderInfo[length - 2].path;
      if (!_.includes(["/", path], from.path)) {
        const {
          path,
          meta: { name },
          query,
        } = from;

        _.assign(vm.routerHeaderInfo[length - 2], {
          path,
          name,
          query,
        });
      }
    });
  },
};
</script>

<style lang="less" scoped>
:deep(.ant-steps) {
  flex-wrap: nowrap;
  padding-bottom: 12px;
  overflow-x: auto;

  .ant-steps-item {
    flex: none;
  }

  .ant-steps-label-vertical .ant-steps-item-content {
    width: unset;
    min-width: 116px;
  }
}

.customer-detail .basic-info :deep(th) {
  width: 10%;
}

.customer-detail .basic-info :deep(td) {
  width: 30%;
}
</style>